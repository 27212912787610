import React from 'react';
import Layout from '../components/layout';
import Newsletter from "../components/newsletter";

export default function NotFound() {

  return(
    <Layout>
<main className="min-h-full bg-cover bg-top sm:bg-top" >
  <div className="max-w-7xl mx-auto px-4 py-16 text-center sm:px-6 sm:py-24 lg:px-8 lg:py-48">
    <p className="text-sm font-semibold text-black text-opacity-50 uppercase tracking-wide">404 error</p>
    <h1 className="mt-2 text-4xl font-extrabold text-white tracking-tight sm:text-5xl">Upp!</h1>
    <p className="mt-2 text-lg font-medium text-black text-opacity-50">Parece que la página no existe</p>
    <div className="mt-6">
      <a href="/" className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-black text-opacity-75 bg-white bg-opacity-75 sm:bg-opacity-25 sm:hover:bg-opacity-50"> Volver al inicio </a>
    </div>
  </div>
</main>
<Newsletter /> 
</Layout>
  )
}
